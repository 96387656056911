exports.components = {
  "component---src-components-template-article-js-content-file-path-opt-build-repo-src-artikelen-antistollingszorg-in-nederland-steeds-veiliger-mdx": () => import("./../../../src/components/template/article.js?__contentFilePath=/opt/build/repo/src/artikelen/antistollingszorg-in-nederland-steeds-veiliger.mdx" /* webpackChunkName: "component---src-components-template-article-js-content-file-path-opt-build-repo-src-artikelen-antistollingszorg-in-nederland-steeds-veiliger-mdx" */),
  "component---src-components-template-article-js-content-file-path-opt-build-repo-src-artikelen-over-de-lta-antistollingszorg-mdx": () => import("./../../../src/components/template/article.js?__contentFilePath=/opt/build/repo/src/artikelen/over-de-lta-antistollingszorg.mdx" /* webpackChunkName: "component---src-components-template-article-js-content-file-path-opt-build-repo-src-artikelen-over-de-lta-antistollingszorg-mdx" */),
  "component---src-components-template-article-js-content-file-path-opt-build-repo-src-artikelen-we-zijn-live-mdx": () => import("./../../../src/components/template/article.js?__contentFilePath=/opt/build/repo/src/artikelen/we-zijn-live.mdx" /* webpackChunkName: "component---src-components-template-article-js-content-file-path-opt-build-repo-src-artikelen-we-zijn-live-mdx" */),
  "component---src-components-template-profile-js": () => import("./../../../src/components/template/profile.js" /* webpackChunkName: "component---src-components-template-profile-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-contact-js": () => import("./../../../src/pages/patient/contact.js" /* webpackChunkName: "component---src-pages-patient-contact-js" */),
  "component---src-pages-patient-contentful-page-anticoagulant-url-js": () => import("./../../../src/pages/patient/{contentfulPageAnticoagulant.url}.js" /* webpackChunkName: "component---src-pages-patient-contentful-page-anticoagulant-url-js" */),
  "component---src-pages-patient-contentful-page-question-url-js": () => import("./../../../src/pages/patient/{contentfulPageQuestion.url}.js" /* webpackChunkName: "component---src-pages-patient-contentful-page-question-url-js" */),
  "component---src-pages-patient-contentful-page-what-is-url-js": () => import("./../../../src/pages/patient/{contentfulPageWhatIs.url}.js" /* webpackChunkName: "component---src-pages-patient-contentful-page-what-is-url-js" */),
  "component---src-pages-patient-een-vraag-stellen-js": () => import("./../../../src/pages/patient/een-vraag-stellen.js" /* webpackChunkName: "component---src-pages-patient-een-vraag-stellen-js" */),
  "component---src-pages-patient-index-js": () => import("./../../../src/pages/patient/index.js" /* webpackChunkName: "component---src-pages-patient-index-js" */),
  "component---src-pages-patient-over-het-kennisplatform-js": () => import("./../../../src/pages/patient/over-het-kennisplatform.js" /* webpackChunkName: "component---src-pages-patient-over-het-kennisplatform-js" */),
  "component---src-pages-patient-veelgestelde-vragen-leefstijl-werk-en-voeding-js": () => import("./../../../src/pages/patient/veelgestelde-vragen/leefstijl-werk-en-voeding.js" /* webpackChunkName: "component---src-pages-patient-veelgestelde-vragen-leefstijl-werk-en-voeding-js" */),
  "component---src-pages-patient-veelgestelde-vragen-medicijnen-en-bijwerkingen-js": () => import("./../../../src/pages/patient/veelgestelde-vragen/medicijnen-en-bijwerkingen.js" /* webpackChunkName: "component---src-pages-patient-veelgestelde-vragen-medicijnen-en-bijwerkingen-js" */),
  "component---src-pages-patient-veelgestelde-vragen-menstruatie-zwangerschap-en-anticonceptie-js": () => import("./../../../src/pages/patient/veelgestelde-vragen/menstruatie-zwangerschap-en-anticonceptie.js" /* webpackChunkName: "component---src-pages-patient-veelgestelde-vragen-menstruatie-zwangerschap-en-anticonceptie-js" */),
  "component---src-pages-patient-veelgestelde-vragen-naast-trombose-andere-behandelingen-js": () => import("./../../../src/pages/patient/veelgestelde-vragen/naast-trombose-andere-behandelingen.js" /* webpackChunkName: "component---src-pages-patient-veelgestelde-vragen-naast-trombose-andere-behandelingen-js" */),
  "component---src-pages-patient-veelgestelde-vragen-ontspanning-sport-en-vakantie-js": () => import("./../../../src/pages/patient/veelgestelde-vragen/ontspanning-sport-en-vakantie.js" /* webpackChunkName: "component---src-pages-patient-veelgestelde-vragen-ontspanning-sport-en-vakantie-js" */),
  "component---src-pages-patient-veelgestelde-vragen-overige-js": () => import("./../../../src/pages/patient/veelgestelde-vragen/overige.js" /* webpackChunkName: "component---src-pages-patient-veelgestelde-vragen-overige-js" */),
  "component---src-pages-professional-404-js": () => import("./../../../src/pages/professional/404.js" /* webpackChunkName: "component---src-pages-professional-404-js" */),
  "component---src-pages-professional-contact-js": () => import("./../../../src/pages/professional/contact.js" /* webpackChunkName: "component---src-pages-professional-contact-js" */),
  "component---src-pages-professional-e-learning-js": () => import("./../../../src/pages/professional/e-learning.js" /* webpackChunkName: "component---src-pages-professional-e-learning-js" */),
  "component---src-pages-professional-index-js": () => import("./../../../src/pages/professional/index.js" /* webpackChunkName: "component---src-pages-professional-index-js" */),
  "component---src-pages-professional-organisatie-vinden-js": () => import("./../../../src/pages/professional/organisatie-vinden.js" /* webpackChunkName: "component---src-pages-professional-organisatie-vinden-js" */),
  "component---src-pages-professional-over-het-kennisplatform-js": () => import("./../../../src/pages/professional/over-het-kennisplatform.js" /* webpackChunkName: "component---src-pages-professional-over-het-kennisplatform-js" */),
  "component---src-pages-professional-protocollen-js": () => import("./../../../src/pages/professional/protocollen.js" /* webpackChunkName: "component---src-pages-professional-protocollen-js" */),
  "component---src-pages-professional-richtlijnen-js": () => import("./../../../src/pages/professional/richtlijnen.js" /* webpackChunkName: "component---src-pages-professional-richtlijnen-js" */),
  "component---src-pages-professional-uitgelicht-js": () => import("./../../../src/pages/professional/uitgelicht.js" /* webpackChunkName: "component---src-pages-professional-uitgelicht-js" */)
}

